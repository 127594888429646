import { useEffect, useRef, useState } from 'react';
import {
  getLastPage,
  handleEnterKeyUp,
} from '@wix/communities-blog-client-common';
import { defaultPaginationProps, PaginationButtonType } from '../constants';

type GetButtonEventHandlersFn = (
  pageOnClick: number,
  type: PaginationButtonType,
) =>
  | {
      onClick: React.MouseEventHandler;
      onKeyUp: React.KeyboardEventHandler;
      tabIndex: number;
    }
  | undefined;

type PaginationChangeEvent = {
  page: number;
  from: number;
  to: number;
  buttonType: PaginationButtonType;
};

export type PaginationState = {
  currentPage: number;
  lastPage: number;
  getButtonEventHandlers: GetButtonEventHandlersFn;
};

type UsePaginationStateProps = {
  page?: number;
  pageSize?: number;
  entityCount?: number;
  onChange?: (event: PaginationChangeEvent) => void;
};

export function usePaginationState({
  page = defaultPaginationProps.page,
  entityCount = defaultPaginationProps.entityCount,
  pageSize = defaultPaginationProps.pageSize,
  onChange,
}: UsePaginationStateProps): PaginationState {
  const lastPropsRef = useRef({ page, entityCount, pageSize });
  const [state, setState] = useState({
    currentPage: page,
    lastPage: getLastPage(entityCount, pageSize),
  });

  useEffect(() => {
    if (
      page !== lastPropsRef.current.page ||
      entityCount !== lastPropsRef.current.entityCount ||
      pageSize !== lastPropsRef.current.pageSize
    ) {
      setState({
        currentPage: page,
        lastPage: getLastPage(entityCount, pageSize),
      });
    }
  }, [page, entityCount, pageSize]);

  const getButtonEventHandlers: GetButtonEventHandlersFn = (
    pageOnClick: number,
    type: PaginationButtonType,
  ) => {
    if (!onChange) {
      return;
    }

    return {
      onClick: handleClick(pageOnClick, type),
      onKeyUp: handleEnterKeyUp(handleClick),
      tabIndex: 0,
    };
  };

  const handleClick = (
    pageOnClick: number,
    buttonType: PaginationButtonType,
  ): React.MouseEventHandler => {
    return (e) => {
      e.preventDefault();

      setState((oldState) => ({ ...oldState, currentPage: pageOnClick }));

      onChange?.({
        page: pageOnClick,
        from: (pageOnClick - 1) * pageSize,
        to: pageOnClick * pageSize,
        buttonType,
      });
    };
  };

  return {
    currentPage: state.currentPage,
    lastPage: state.lastPage,
    getButtonEventHandlers,
  };
}
