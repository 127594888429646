import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';

import styles from './separator.scss';

export const HorizontalSeparator = ({
  className,
  inverted,
  separatorBackgroundColorClassName,
}) => (
  <div
    className={classNames(
      styles.horizontal,
      className,
      getClassName(inverted, separatorBackgroundColorClassName),
    )}
  />
);

HorizontalSeparator.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  separatorBackgroundColorClassName: PropTypes.string,
};

export const HorizontalSeparatorForPostCard =
  withLayoutColorClasses(HorizontalSeparator);

function getClassName(inverted, separatorBackgroundColorClassName) {
  return inverted
    ? 'blog-inverted-separator-background-color'
    : separatorBackgroundColorClassName || 'blog-separator-background-color';
}

const BulletSeparator = ({
  className,
  inverted,
  separatorBackgroundColorClassName,
}) => (
  <div
    className={classNames(
      styles.bullet,
      className,
      getClassName(inverted, separatorBackgroundColorClassName),
    )}
  />
);

BulletSeparator.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  separatorBackgroundColorClassName: PropTypes.string.isRequired,
};

export const BulletSeparatorForPost = withLayoutColorClasses(BulletSeparator);
